<template>
  <v-card>
    <v-toolbar dark color="primary" id="mfa-toolbar">
      <v-card-title id="mfa-title">{{ mfaTitle }}</v-card-title>
    </v-toolbar>
    <v-card-text>{{ mfaText }}</v-card-text>
    <v-form class="mfa-form" ref="form" @submit.prevent="submit()">
      <v-text-field
        height="70"
        type="number"
        outlined
        counter="7"
        v-model="code"
        :rules="[rules.code_length]"
      ></v-text-field>
      <v-btn color="secondary" rounded large type="submit">Submit</v-btn>
    </v-form>
  </v-card>
</template>

<script>
export default {
  props: {
    mfaTitle: {
      type: String,
      default: "Two Factor Authentication"
    },
    mfaText: {
      type: String,
      default: "Please enter your authentication code below."
    }
  },
  data() {
    return {
      code: null,
      rules: {
        code_length: value =>
          value != null ? value.length === 7 || "Must Enter a 7 Digit Code" : ""
      }
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.$emit("codesubmission", this.code);
        this.code = "";
      }
    }
  }
};
</script>

<style scoped>
.mfa-form {
  padding: 10px 40px;
}
.v-card .v-card__text {
  padding: 10px 40px;
}
.v-input >>> input {
  font-size: 3em;
  height: 100px;
  max-height: none;
}

@media (max-width: 415px) {
  #mfa-toolbar {
    height: 100px !important;
  }
  #mfa-title {
    word-break: normal;
    text-align: center;
    padding-top: 60px;
  }
  .v-card .v-card__text {
    padding: 10px 40px;
    text-align: center;
  }
  .v-input >>> input {
    font-size: 2.5em;
  }
}

/* Chrome, Safari, Edge, Opera */
.v-input >>> input::-webkit-outer-spin-button,
.v-input >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.v-input >>> input[type="number"] {
  -moz-appearance: textfield;
}
</style>
